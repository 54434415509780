/* Bildy custom reset */

html, object, img, ins, kbd, q, s, samp, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block; }

iframe {
	border: 0; }

h1, h2, h3, h4, h5, h6, p, blockquote {
	margin: 0; }

*,
*::before,
*::after {
	box-sizing: border-box; }

html {
	scroll-behavior: smooth;
	margin-top: 0;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
	width: device-width; }

body {
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	scroll-behavior: smooth;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	min-height: 100vh;
	font-family: 'Roboto', sans-serif; }

[tabindex="-1"]:focus {
	outline: 0 !important; }

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible; }

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0; }

p {
	margin-top: 0;
	margin-bottom: 0; }

ul, dl {
	list-style: none;
	margin: 0;
	padding: 0; }

ol {
	margin: 0; }

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none; }

table {
	border-collapse: collapse;
	border-spacing: 0;

	th {}
	text-align: inherit; }

img {
	-ms-interpolation-mode: bicubic; }

a,
a:hover,
a:focus {
	text-decoration: none;
	background-color: transparent;
	text-decoration-skip-ink: auto; }

pre {
	margin: 0;
	overflow: auto;
	-ms-overflow-style: scrollbar; }

img {
	vertical-align: middle;
	border-style: none; }

svg {
	overflow: hidden;
	vertical-align: middle; }

label {
	display: inline-block; }

input,
textarea {
	box-shadow: none;
	margin: 0;
	font-family: 'Roboto', sans-serif; }

input[type=text],
input[type="number"],
input[type="email"],
input[type="button"],
input[type="tel"],
input[type="search"],
textarea,
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	box-shadow: none;
	margin: 0;
	border-radius: 0;

	&:focus {
		outline: 1px dotted; } }

input[type='number'] {
	-moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none; }

[type="search"] {
	outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none; }

textarea {
	overflow: auto;
	resize: vertical; }

select {

	&::-ms-expand {
		display: none; } }

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none; }

address,
cite, em {
	font-style: normal; }

fieldset {
	min-width: 0; }

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	line-height: inherit;
	color: inherit;
	white-space: normal; }

progress {
	vertical-align: baseline; }

main.main {

	@media screen and (min-width: 992px) {
		display: flex;
		min-height: inherit; }

	& > .content-area {
		width: 100%; } }

.section {
	flex: 1 1 0;
	overflow: hidden; }

.container {
	padding: 0 15px;
	max-width: 1110px;
	margin: 0 auto;

	@media screen and (min-width: 768px) and (max-width: 991px) {
		padding: 0 30px; } }

.title {
	font-size: 32px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	text-align: center;
	color: #000;
	max-width: 900px;
	margin: 0 auto;

	span {
		color: #018903; }

	@media screen and (max-width: 767px) {
		font-size: 26px; } }

.subtitle {
	font-size: 24px;
	text-align: center;
	margin: 8px 0 30px;

	@media screen and (max-width: 767px) {
		font-size: 18px; } }

.description {
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #888888;
	max-width: 740px;
	margin: 0 auto;

	strong {
		font-weight: bold; }

	@media screen and (max-width: 767px) {
		font-size: 14px; }

	@media screen and (max-width: 991px) {

		br {
			display: none; } } }

.section_container {
	max-width: 1300px;

	@media screen and (max-width: 991px) {
		padding: 15px;

		.section_aside {
			margin-top: 50px; } }

	@media screen and (min-width: 992px) {
		display: flex;

		@media screen and (max-width: 1199px) {
			padding: 25px;

			.section_content {
				flex: 1 1 400px; }

			.section_aside {
				flex: 2 1 0;
				margin-top: 100px; } } }

	@media screen and (min-width: 1200px) {
		padding: 50px;

		.section_content {
			flex: 2 1 400px; }

		.section_aside {
			flex: 1 1 0;
			margin-top: 100px; } } }

.section_aside {
	visibility: hidden; }

table.stylized_table,
.section_content table,
.woocommerce table.woocommerce-table--order-details,
.woocommerce table.woocommerce-checkout-review-order-table {
	border-collapse: collapse;
	border-spacing: 0;
	margin: 50px 0;
	width: 75%;

	@media screen and (max-width: 1199px) {
		width: 100%; }

	td,
	th {
		border: 1px solid #ebe9eb;
		padding: 10px; } }

.woocommerce table.woocommerce-table--order-details,
.woocommerce table.woocommerce-checkout-review-order-table {
	width: 100%;
	margin-top: 0; }

.site-main {
	flex: 1 1 0; }

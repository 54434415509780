.entry-title {
	font-size: 32px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.25;
	letter-spacing: normal;
	color: #000;
	margin-bottom: 10px; }

.woocommerce,
.woocommerce-page {

	.woocommerce-notices-wrapper,
	.woocommerce-result-count {
		letter-spacing: 0.2px;
		line-height: 22px; }

	.woocommerce-notices-wrapper {
		color: #018903; }

	.woocommerce-ordering {
		width: 100%;
		max-width: 250px;
		position: relative;

		@media screen and (max-width: 767px) {
			float: none;
			clear: both; }

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			background: url('../img/down-arrow.svg') right/contain no-repeat;
			width: 15px;
			height: 15px;
			z-index: 5; }

		select {
			width: 100%;
			border: 1px solid #888888;
			border-radius: 3px;
			height: 30px;
			padding: 0 30px 0 15px;
			box-shadow: none;
			margin: 0;
			cursor: pointer;
			position: relative;
			z-index: 10;
			background-color: transparent; } }

	ul.products[class*=columns-] {
		display: flex;
		flex-wrap: wrap;
		padding-top: 50px;
		margin: 0 -10px;

		@media screen and (max-width: 767px) {
			margin: 0 -5px; }

		li.product {
			width: calc(25% - 20px);
			margin: 0 10px 20px;
			position: relative;
			border-radius: 3px;
			transition: all 0.2s ease-out;

			&:hover {
				box-shadow: 0 4px 8px -2px rgba(9,30,66,.25), 0 0 0 1px rgba(9,30,66,.08);
				transform: translateY(-10px);
				transition: all 0.2s ease-out; }

			@media screen and (max-width: 767px) {
				width: calc(50% - 10px);
				margin: 0 5px 20px; }

			@media screen and (min-width: 768px) and (max-width: 1199px) {
				width: calc(33.33% - 20px);
				margin: 0 10px 20px; }

			.added_to_cart {
				display: none !important; }

			.woocommerce-loop-product__link {
				display: block;
				padding: 20px 20px 70px;
				position: relative;
				z-index: 5;
				height: 100%;

				.onsale {
					width: 75px;
					height: 75px;
					background-color: #ff366d;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 18px;

					@media screen and (max-width: 767px) {
						width: 60px;
						height: 60px;
						font-size: 14px; }

					@media screen and (min-width: 768px) and (max-width: 1199px) {
						width: 67px;
						height: 67px;
						font-size: 16px; } }

				@media screen and (max-width: 767px) {
					padding: 10px 10px 50px; } }

			.product_type_variable,
			.add_to_cart_button {
				position: absolute;
				text-align: center;
				bottom: 20px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 10;

				@media screen and (max-width: 767px) {
					font-size: 12px;
					max-width: 110px;
					padding: 10px 10px; }

				@media screen and (max-width: 1199px) {
					bottom: 10px;
					margin-top: 0;
					padding: 15px 10px;
					width: 100%;
					max-width: 150px; }

				@media screen and (min-width: 1200px) {
					width: 100%;
					max-width: 160px; } }

			.woocommerce-loop-product__title {
				text-align: center;
				color: #000;

				@media screen and (max-width: 767px) {
					font-size: 0.875em;
					padding-bottom: 0; } }

			.price {
				color: #018903;
				display: block;
				text-align: center;
				font-weight: 700;

				@media screen and (max-width: 767px) {
					font-size: 0.75em; }

				ins {
					text-decoration: none;
					font-weight: inherit; }

				.woocommerce-price-suffix {
					display: none; } } } } }

.promo {
	padding: 102px 0 50px;

	@media screen and (max-width: 767px) {
		padding: 50px 0 30px; } }

.promo_cards {

	@media screen and (max-width: 991px) {
		margin: 40px 0 0; }

	@media screen and (min-width: 992px) {
		display: flex;
		flex-wrap: wrap;
		margin: 66px -20px 0; } }

.promo_card {
	border-radius: 5px {
  background-color: rgba(1, 137, 3, 0.03); }
	box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.25);
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		z-index: 5;
		background-color: #f9fcf9;
		transform: rotate(36.5deg);
		width: 700px;
		height: 700px;
		bottom: -105%;
		left: -105%;

		@media screen and (min-width: 992px) and (max-width: 1199px) {
			bottom: -75%;
			left: -185%; } }

	@media screen and (max-width: 991px) {
		margin: 0 auto 30px;
		max-width: 345px; }

	@media screen and (min-width: 992px) {
		margin: 0 20px 30px;
		width: calc(33.33% - 40px); }

	a {
		padding: 88px 25px 52px 25px;
		display: block;
		height: 100%;
		text-align: center;
		position: relative;
		z-index: 10;

		img,
		svg {
			max-width: 73px;
			max-height: 80px; }

		span {
			display: block;
			font-size: 18px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: 0.5px;
			text-align: center;
			color: #000000;
			margin: 58px 0 17px; }

		i {
			display: block;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.38;
			letter-spacing: normal;
			text-align: center;
			color: #727272; } } }

.woocommerce {

	.woocommerce-cart-form,
	.cart-collaterals {
		max-width: 800px; }

	table.cart .product-thumbnail {
		display: table-cell;

		@media screen and (max-width: 767px) {
			display: none; } }

	.woocommerce-cart-form {
		margin-top: 50px;

		.attachment-woocommerce_thumbnail {
			display: block;
			max-width: 100px; }

		a.remove {
			color: #ff366d !important;

			&:hover {
				color: #fff !important;
				background: #ff366d; } }

		a:not(.remove) {
			color: #888;
			text-decoration: underline;
			transition: color .1s linear;

			&:hover {
				color: #000;
				transition: color .1s linear; } }

		input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {
			border: 1px solid #888;
			border-radius: 3px;
			height: 30px;
			box-shadow: none;
			margin: 0; }

		button[type="submit"] {
			display: inline-block !important;
			width: auto !important;
			float: right !important; } }

	.cart_totals {

		h2 {
			font-size: 16px;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #018903;
			display: block;
			margin: 50px 0 15px;
			font-weight: 700; } }

	.wc-proceed-to-checkout {

		.checkout-button {
			float: right;
			background-color: #018903 !important; } } }

.section_content {

	@media screen and (min-width: 992px) and (max-width: 1199px) {
		padding-right: 30px; }

	@media screen and (min-width: 1200px) {
		padding-right: 70px; }

	h1 {
		font-size: 32px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		color: #000;
		margin-bottom: 60px; }

	h2, h3, h4, h5, h6 {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #018903;
		display: block;
		margin: 50px 0 15px;
		font-weight: bold; }

	p {
		margin: 15px 0;
		letter-spacing: 0.2px;
		line-height: 22px; }

	img {
		display: block;
		max-width: 100%;
		height: auto;
		margin: 25px 0; }

	ol, ul {
		margin: 25px 0;
		padding-left: 50px;

		li {
			letter-spacing: 0.2px;
			line-height: 22px; } }

	ul {
		list-style-type: disc; }

	a {
		color: #888888;
		text-decoration: underline;
		transition: color 0.1s linear;

		&:hover {
			color: #000;
			transition: color 0.1s linear; } }

	strong {
		font-weight: bold; }

	i {
		font-style: italic; } }

.aside_block {
	border-bottom: 1px solid #888888;
	padding-bottom: 15px;
	margin-bottom: 30px;

	h3 {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		display: block;
		margin: 0 0 10px;
		font-weight: bold;

		@media screen and (max-width: 767px) {
			font-size: 14px; } }

	form {
		position: relative;

		input[type="text"],
		input[type="search"] {
			width: 100%;
			border: 1px solid #888888;
			border-radius: 3px;
			height: 30px;
			padding: 0 30px 0 15px; }

		input[type="submit"],
		button[type="submit"] {
			background-color: transparent;
			background: url('../img/sidebar-search.png') center/auto no-repeat;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			border: 0;
			padding: 0;
			width: 28px;
			height: 28px;
			font-size: 0;
			outline: none;
			cursor: pointer; } }

	ul {
		display: flex;
		flex-wrap: wrap;

		li {
			margin: 0 5px 10px 0;

			a {
				border: 1px solid #018903;
				padding: 5px 10px;
				color: #000;
				border-radius: 3px;
				font-size: 14px;
				display: block;
				transition: all 0.15s ease-out;

				&:hover {
					background-color: #018903;
					color: #fff;
					transition: all 0.15s ease-out; } } } } }

.section_product {
	flex: none;
	width: 100%;

	h1 {
		font-size: 32px;
		font-weight: 700;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		color: #000;
		margin-bottom: 30px; }

	.woocommerce-product-details__short-description {

		p {
			margin-top: 1em; } }

	div.product {
		display: flex;
		flex-wrap: wrap;

		div.images.woocommerce-product-gallery {
			flex: none;
			width: 500px;
			pointer-events: none;

			@media screen and (max-width: 767px) {
				width: 100%;
				margin-top: 2em; }

			@media screen and (min-width: 768px) and (max-width: 1199px) {
				width: 400px; }

			.woocommerce-product-gallery__trigger {
				display: none; } }

		ins {
			text-decoration: none; }

		.onsale {
			width: 100px;
			height: 100px;
			background-color: #ff366d;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 22px;
			top: 0;
			left: 0;

			@media screen and (max-width: 767px) {
				width: 80px;
				height: 80px;
				font-size: 18px; }

			@media screen and (min-width: 768px) and (max-width: 1199px) {
				width: 85px;
				height: 85px;
				font-size: 20px; } }

		img.zoomImg {
			display: none !important; }

		div.summary {
			flex: 1 1 0;
			position: relative;
			transform: translateY(20px);
			transition: transform 0.4s ease-out;

			& > *:not(.--preloader) {
				opacity: 0;
				transition: opacity 0.4s ease-out; }

			&.--active > * {
				opacity: 1;
				transition: opacity 0.4s ease-out; }

			&.--active {
				transform: translateY(0);
				transition: transform 0.4s ease-out;

				.--preloader {
					opacity: 0 !important;
					transition: opacity 0.4s ease-out; } }

			.--preloader {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				transition: opacity 0.2s ease-out; }

			p.price,
			span.price {
				color: #018903;
				font-size: 36px;
				font-weight: 600;

				del {
					margin-right: 10px; } }

			.woocommerce-variation-price {

				p.price,
				span.price {
					font-size: 22px;
					margin-bottom: 15px;
					display: block;
					margin-top: 30px;

					del {
						margin-right: 0; } } }

			form.cart {
				margin-top: 65px;
				margin-bottom: 20px;
				width: 75%;

				&:before,
				&:after {
					content: none; }

				@media screen and (max-width: 767px) {
					margin-top: 20px;
					width: 100%; }

				.variations {
					margin-bottom: 20px;

					tr {
						display: block; }

					td.label {
						margin-bottom: 10px;
						display: block;

						label {
							letter-spacing: 0.2px;
							line-height: 22px; } }

					td.value {
						position: relative;
						width: 325px;
						display: flex;
						align-items: center;

						@media screen and (max-width: 767px) {
							width: 100%; }

						&:after {
							content: '';
							position: absolute;
							top: 50%;
							right: 85px;
							transform: translateY(-50%);
							background: url('../img/down-arrow.svg') right/contain no-repeat;
							width: 15px;
							height: 15px;
							z-index: 5; } }

					select {
						flex: 1 1 0;
						border: 1px solid #888888;
						border-radius: 3px;
						height: 30px;
						padding: 0 30px 0 15px;
						-webkit-box-shadow: none;
						box-shadow: none;
						margin: 0;
						cursor: pointer;
						position: relative;
						z-index: 10;
						background-color: transparent; }

					.reset_variations {
						flex: none;
						text-align: center;
						line-height: 1;
						padding: .618em 1em;
						border-radius: 3px;
						color: #515151;
						background-color: #ebe9eb;
						font-weight: 700;
						width: 70px;
						margin-left: 5px; } }

				.variations_button {
					display: flex; }

				div.quantity {
					margin-right: 5px;

					.qty {
						height: 100%;
						border: 1px solid #888888;
						border-radius: 3px;
						min-height: 35.75px; } }

				.button {
					float: none;
					background-color: #018903; } }

			.product_meta {
				font-size: 14px;

				a {
					color: #888888;
					text-decoration: underline;
					transition: color 0.1s linear;

					&:hover {
						color: #000;
						transition: color 0.1s linear; } } } }

		.woocommerce-tabs {
			flex: none;
			width: 100%;

			@media screen and (max-width: 767px) {
				margin: 50px 0; }

			h2 {
				font-size: 18px;
				margin-bottom: 10px; }

			.panel {

				p {
					margin-bottom: 1em; } } }

		.related {
			flex: none;
			width: 100%; } } }

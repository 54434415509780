.header {
	background-color: #fbfbfb;

	@media screen and (max-width: 991px) {
		display: flex;

		&.--active {

			.header_nav {

				& > ul {
					transform: translate3d(0, 0, 0);
					box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25);
					transition: transform 0.2s ease-out; } }

			.header_burger {
				position: fixed;
				right: 0; } } }

	@media screen and (min-width: 992px) {
		width: 20vw;
		max-width: 300px;
		flex: none; } }

.header_logo {
	display: block;

	img {
		width: 100%;
		height: auto; }

	@media screen and (max-width: 991px) {
		flex: none;
		width: 15vw;
		max-width: 170px; } }

.header_nav {

	@media screen and (max-width: 991px) {
		flex: 1 1 0;
		display: flex;
		align-items: center;
		justify-content: flex-end; }

	@media screen and (min-width: 992px) {
		margin: 41px 0;
		border-top: 1px solid #727272; }

	& > ul {

		@media screen and (max-width: 991px) {
			position: fixed;
			background-color: #fff;
			z-index: 5000;
			top: 0;
			right: 0;
			width: 75vw;
			max-width: 460px;
			padding: 50px 0;
			height: 100vh;
			transform: translate3d(100%, 0, 0);
			transition: transform 0.2s ease-out;
			overflow: auto; } }

	.menu-item {
		border-bottom: 1px solid #727272;

		&.current-menu-item,
		&.current_page_parent {

			a {
				background-color: #ebebeb; } }

		a {
			font-weight: bold;
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			color: #000000;
			display: block;
			padding: 12px 10px 11px 25px;

			@media screen and (min-width: 992px) {
				background-color: #fbfbfb; }

			&:hover {
				background-color: #ebebeb; } }

		.sub-menu {

			.menu-item {
				border-bottom: 1px solid transparent;

				&.current-menu-item,
				&.current_page_parent {

					a {
						background-color: #ebebeb; } }

				a {
					padding-left: 50px;

					@media screen and (max-width: 767px) {
						padding-left: 40px; }

					@media screen and (min-width: 992px) {
						background-color: #fbfbfb; } } } } } }

.header_burger {

	@media screen and (max-width: 991px) {
		position: relative;
		z-index: 10000;
		margin: 0 0 0 auto;
		float: right;
		padding: 0;
		outline: none;
		padding: 10px 15px;
		border: 0;
		background-color: transparent;

		div {
			width: 30px;
			height: 20px;
			position: relative;

			span {
				width: 100%;
				height: 2px;
				background-color: #000;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate3d(0, -50%, 0); }

			&:before,
			&:after {
				content: '';
				position: absolute;
				right: 0;
				background-color: #000;
				height: 2px; }

			&:before {
				top: 0;
				width: 100%; }

			&:after {
				bottom: 0;
				width: 70%; } } }

	@media screen and (min-width: 992px) {
		display: none; } }

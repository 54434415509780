.woocommerce-message {
	border-top-color: #018903; }

.woocommerce-message::before {
	color: #018903; }

.woocommerce {

	.attachment-woocommerce_thumbnail {
		max-width: 500px;
		height: auto;
		display: none;

		@media screen and (max-width: 767px) {
			max-width: 100%; } }

	.woocommerce-checkout {
		display: flex;
		position: relative;
		margin-bottom: 50px;
		flex-wrap: wrap;

		@media screen and (max-width: 1199px) {
			display: block; }

		[class^="col-"] {

			h3 {
				margin-bottom: 10px; } }

		.woocommerce-NoticeGroup {
			flex: none;
			width: 100%;

			.woocommerce-error {
				border-top-color: #ff366d;

				&:before {
					color: #ff366d; }

				li {
					margin-bottom: 10px; } }

			.woocommerce-info,
			.woocommerce-message {
				border-top-color: #018903;

				&:before {
					color: #018903; }

				li {
					margin-bottom: 10px; } } }

		table.shop_table th {
			font-size: 16px; }

		#customer_details {
			flex: none;

			@media screen and (max-width: 767px) {
				width: 100%; }

			@media screen and (min-width: 768px) {
				width: 450px;
				margin-right: 75px;
				margin-bottom: 50px; }

			[class^="col-"] {
				width: 100%; } }

		.form-row {
			width: 100%;
			padding: 0;
			margin-bottom: 15px;

			&.woocommerce-invalid {

				label {
					color: #ff366d; } }

			&#billing_country_field {

				.woocommerce-input-wrapper {
					position: relative;

					&:after {
						content: '';
						position: absolute;
						top: 50%;
						right: 10px;
						transform: translateY(-50%);
						background: url(../img/down-arrow.svg) right/contain no-repeat;
						width: 15px;
						height: 15px;
						z-index: 5; } } }

			input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
			select {
				border: 1px solid #888888;
				border-radius: 3px;
				height: 40px;
				padding: 0 30px 0 15px;
				box-shadow: none;
				margin: 0;
				position: relative;
				z-index: 10;
				background-color: transparent; }

			select {
				cursor: pointer;

				& + span {
					display: none; } }

			textarea {
				border: 1px solid #888888;
				border-radius: 3px;
				height: 100px;
				padding: 10px;
				box-shadow: none;
				margin: 0;
				position: relative;
				z-index: 10;
				background-color: transparent; } }

		#order_review_heading {

			@media screen and (max-width: 1199px) {
				margin-bottom: 20px; }

			@media screen and (min-width: 1200px) {
				margin-bottom: 40px; } }

		#order_review {
			flex: 1 1 0;
			position: relative;

			@media screen and (max-width: 1199px) {
				margin-top: 20px; }

			@media screen and (min-width: 1200px) {
				margin-top: 0; } } }

	.wc-paytrail-methods {
		display: flex;
		flex-wrap: wrap;

		.wc-paytrail-method {
			width: calc(25% - 10px);
			margin: 0 5px 10px;
			border-radius: 5px; } }

	.form-row.place-order {
		margin-left: auto;
		width: 50%;

		@media screen and (max-width: 767px) {
			width: 100%; }

		.woocommerce-privacy-policy-text {

			p {
				font-size: 0.875rem;
				text-align: right;
				margin-bottom: 15px;

				a {
					color: #888888;
					text-decoration: underline;
					transition: color 0.1s linear;

					&:hover {
						color: #000;
						transition: color 0.1s linear; } } } }

		button[type="submit"] {
			background-color: #018903;
			padding: 20px;
			text-transform: uppercase; } } }

.woocommerce .woocommerce-cart-form,
.woocommerce .cart-collaterals {
  max-width: 800px; }

.woocommerce table.cart .product-thumbnail {
  display: table-cell; }
  @media screen and (max-width: 767px) {
    .woocommerce table.cart .product-thumbnail {
      display: none; } }

.woocommerce .woocommerce-cart-form {
  margin-top: 50px; }
  .woocommerce .woocommerce-cart-form .attachment-woocommerce_thumbnail {
    display: block;
    max-width: 100px; }
  .woocommerce .woocommerce-cart-form a.remove {
    color: #ff366d !important; }
    .woocommerce .woocommerce-cart-form a.remove:hover {
      color: #fff !important;
      background: #ff366d; }
  .woocommerce .woocommerce-cart-form a:not(.remove) {
    color: #888;
    text-decoration: underline;
    transition: color .1s linear; }
    .woocommerce .woocommerce-cart-form a:not(.remove):hover {
      color: #000;
      transition: color .1s linear; }
  .woocommerce .woocommerce-cart-form input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {
    border: 1px solid #888;
    border-radius: 3px;
    height: 30px;
    box-shadow: none;
    margin: 0; }
  .woocommerce .woocommerce-cart-form button[type="submit"] {
    display: inline-block !important;
    width: auto !important;
    float: right !important; }

.woocommerce .cart_totals h2 {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #018903;
  display: block;
  margin: 50px 0 15px;
  font-weight: 700; }

.woocommerce .wc-proceed-to-checkout .checkout-button {
  float: right;
  background-color: #018903 !important; }

.woocommerce-message {
  border-top-color: #018903; }

.woocommerce-message::before {
  color: #018903; }

.woocommerce .attachment-woocommerce_thumbnail {
  max-width: 500px;
  height: auto;
  display: none; }
  @media screen and (max-width: 767px) {
    .woocommerce .attachment-woocommerce_thumbnail {
      max-width: 100%; } }

.woocommerce .woocommerce-checkout {
  display: flex;
  position: relative;
  margin-bottom: 50px;
  flex-wrap: wrap; }
  @media screen and (max-width: 1199px) {
    .woocommerce .woocommerce-checkout {
      display: block; } }
  .woocommerce .woocommerce-checkout [class^="col-"] h3 {
    margin-bottom: 10px; }
  .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup {
    flex: none;
    width: 100%; }
    .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup .woocommerce-error {
      border-top-color: #ff366d; }
      .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup .woocommerce-error:before {
        color: #ff366d; }
      .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup .woocommerce-error li {
        margin-bottom: 10px; }
    .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup .woocommerce-info,
    .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup .woocommerce-message {
      border-top-color: #018903; }
      .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup .woocommerce-info:before,
      .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup .woocommerce-message:before {
        color: #018903; }
      .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup .woocommerce-info li,
      .woocommerce .woocommerce-checkout .woocommerce-NoticeGroup .woocommerce-message li {
        margin-bottom: 10px; }
  .woocommerce .woocommerce-checkout table.shop_table th {
    font-size: 16px; }
  .woocommerce .woocommerce-checkout #customer_details {
    flex: none; }
    @media screen and (max-width: 767px) {
      .woocommerce .woocommerce-checkout #customer_details {
        width: 100%; } }
    @media screen and (min-width: 768px) {
      .woocommerce .woocommerce-checkout #customer_details {
        width: 450px;
        margin-right: 75px;
        margin-bottom: 50px; } }
    .woocommerce .woocommerce-checkout #customer_details [class^="col-"] {
      width: 100%; }
  .woocommerce .woocommerce-checkout .form-row {
    width: 100%;
    padding: 0;
    margin-bottom: 15px; }
    .woocommerce .woocommerce-checkout .form-row.woocommerce-invalid label {
      color: #ff366d; }
    .woocommerce .woocommerce-checkout .form-row#billing_country_field .woocommerce-input-wrapper {
      position: relative; }
      .woocommerce .woocommerce-checkout .form-row#billing_country_field .woocommerce-input-wrapper:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        background: url(../img/down-arrow.svg) right/contain no-repeat;
        width: 15px;
        height: 15px;
        z-index: 5; }
    .woocommerce .woocommerce-checkout .form-row input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
    .woocommerce .woocommerce-checkout .form-row select {
      border: 1px solid #888888;
      border-radius: 3px;
      height: 40px;
      padding: 0 30px 0 15px;
      box-shadow: none;
      margin: 0;
      position: relative;
      z-index: 10;
      background-color: transparent; }
    .woocommerce .woocommerce-checkout .form-row select {
      cursor: pointer; }
      .woocommerce .woocommerce-checkout .form-row select + span {
        display: none; }
    .woocommerce .woocommerce-checkout .form-row textarea {
      border: 1px solid #888888;
      border-radius: 3px;
      height: 100px;
      padding: 10px;
      box-shadow: none;
      margin: 0;
      position: relative;
      z-index: 10;
      background-color: transparent; }
  @media screen and (max-width: 1199px) {
    .woocommerce .woocommerce-checkout #order_review_heading {
      margin-bottom: 20px; } }
  @media screen and (min-width: 1200px) {
    .woocommerce .woocommerce-checkout #order_review_heading {
      margin-bottom: 40px; } }
  .woocommerce .woocommerce-checkout #order_review {
    flex: 1 1 0;
    position: relative; }
    @media screen and (max-width: 1199px) {
      .woocommerce .woocommerce-checkout #order_review {
        margin-top: 20px; } }
    @media screen and (min-width: 1200px) {
      .woocommerce .woocommerce-checkout #order_review {
        margin-top: 0; } }

.woocommerce .wc-paytrail-methods {
  display: flex;
  flex-wrap: wrap; }
  .woocommerce .wc-paytrail-methods .wc-paytrail-method {
    width: calc(25% - 10px);
    margin: 0 5px 10px;
    border-radius: 5px; }

.woocommerce .form-row.place-order {
  margin-left: auto;
  width: 50%; }
  @media screen and (max-width: 767px) {
    .woocommerce .form-row.place-order {
      width: 100%; } }
  .woocommerce .form-row.place-order .woocommerce-privacy-policy-text p {
    font-size: 0.875rem;
    text-align: right;
    margin-bottom: 15px; }
    .woocommerce .form-row.place-order .woocommerce-privacy-policy-text p a {
      color: #888888;
      text-decoration: underline;
      transition: color 0.1s linear; }
      .woocommerce .form-row.place-order .woocommerce-privacy-policy-text p a:hover {
        color: #000;
        transition: color 0.1s linear; }
  .woocommerce .form-row.place-order button[type="submit"] {
    background-color: #018903;
    padding: 20px;
    text-transform: uppercase; }

.woocommerce .woocommerce-thankyou-order-received {
  font-weight: bold;
  margin-bottom: 20px;
  color: #018903; }

.woocommerce ul.order_details li {
  color: #888; }
  @media screen and (max-width: 767px) {
    .woocommerce ul.order_details li {
      float: none;
      margin: 0 0 5px 0;
      border: 0;
      font-size: 0.75rem; } }
  .woocommerce ul.order_details li strong {
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .woocommerce ul.order_details li strong {
        display: inline;
        font-size: 0.75rem; } }

.woocommerce .woocommerce-order-details__title {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #018903;
  display: block;
  margin: 50px 0 15px;
  font-weight: bold; }

.woocommerce .woocommerce-table--order-details td a {
  color: #888888;
  text-decoration: underline;
  transition: color 0.1s linear; }

.woocommerce .woocommerce-table--order-details td:hover {
  color: #000;
  transition: color 0.1s linear; }

.woocommerce .woocommerce-customer-details .woocommerce-column__title {
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #018903;
  display: block;
  margin: 50px 0 15px;
  font-weight: bold; }

.woocommerce .woocommerce-customer-details address {
  padding: 1em;
  line-height: 24px; }

.events {
  padding: 62px 0 95px; }
  @media screen and (max-width: 767px) {
    .events {
      padding: 40px 0 50px; } }

.events_list {
  margin-top: 60px;
  border-top: 1px solid #727272; }
  @media screen and (max-width: 767px) {
    .events_list {
      margin-top: 40px; } }

.events_item {
  border-bottom: 1px solid #727272; }
  .events_item a {
    display: block;
    padding: 28px 0 25px; }
    @media screen and (max-width: 767px) {
      .events_item a {
        padding: 23px 0 20px; } }
    .events_item a time {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #018903;
      display: block;
      margin-bottom: 10px; }
      @media screen and (max-width: 767px) {
        .events_item a time {
          font-size: 14px; } }
    .events_item a span {
      font-size: 22px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      display: block; }
      @media screen and (max-width: 767px) {
        .events_item a span {
          font-size: 18px; } }

.hero {
  width: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .hero {
      height: calc(100vh - 100px); } }
  @media screen and (min-width: 768px) {
    .hero {
      min-height: 700px;
      height: 85vh; } }

.hero_content {
  text-align: center; }

.hero_title {
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }
  @media screen and (max-width: 767px) {
    .hero_title {
      font-size: 50px; } }

.hero_subtitle {
  text-transform: uppercase;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position: relative;
  top: 30px; }
  @media screen and (max-width: 767px) {
    .hero_subtitle {
      font-size: 16px; } }

.hero_cta {
  padding: 19px 47px 21px;
  text-align: center;
  display: inline-block;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #018903;
  background-color: #fff !important;
  border-radius: 35px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  margin-top: 50px;
  transition: background-color 0.2s ease-out; }
  @media screen and (max-width: 767px) {
    .hero_cta {
      max-width: 300px;
      font-size: 22px;
      padding: 15px 20px 17px; } }
  .hero_cta:hover {
    background-color: #e5f3e5 !important;
    transition: background-color 0.2s ease-out; }

.section_product {
  flex: none;
  width: 100%; }
  .section_product h1 {
    font-size: 32px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000;
    margin-bottom: 30px; }
  .section_product .woocommerce-product-details__short-description p {
    margin-top: 1em; }
  .section_product div.product {
    display: flex;
    flex-wrap: wrap; }
    .section_product div.product div.images.woocommerce-product-gallery {
      flex: none;
      width: 500px;
      pointer-events: none; }
      @media screen and (max-width: 767px) {
        .section_product div.product div.images.woocommerce-product-gallery {
          width: 100%;
          margin-top: 2em; } }
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        .section_product div.product div.images.woocommerce-product-gallery {
          width: 400px; } }
      .section_product div.product div.images.woocommerce-product-gallery .woocommerce-product-gallery__trigger {
        display: none; }
    .section_product div.product ins {
      text-decoration: none; }
    .section_product div.product .onsale {
      width: 100px;
      height: 100px;
      background-color: #ff366d;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      top: 0;
      left: 0; }
      @media screen and (max-width: 767px) {
        .section_product div.product .onsale {
          width: 80px;
          height: 80px;
          font-size: 18px; } }
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        .section_product div.product .onsale {
          width: 85px;
          height: 85px;
          font-size: 20px; } }
    .section_product div.product img.zoomImg {
      display: none !important; }
    .section_product div.product div.summary {
      flex: 1 1 0;
      position: relative;
      transform: translateY(20px);
      transition: transform 0.4s ease-out; }
      .section_product div.product div.summary > *:not(.--preloader) {
        opacity: 0;
        transition: opacity 0.4s ease-out; }
      .section_product div.product div.summary.--active > * {
        opacity: 1;
        transition: opacity 0.4s ease-out; }
      .section_product div.product div.summary.--active {
        transform: translateY(0);
        transition: transform 0.4s ease-out; }
        .section_product div.product div.summary.--active .--preloader {
          opacity: 0 !important;
          transition: opacity 0.4s ease-out; }
      .section_product div.product div.summary .--preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 0.2s ease-out; }
      .section_product div.product div.summary p.price,
      .section_product div.product div.summary span.price {
        color: #018903;
        font-size: 36px;
        font-weight: 600; }
        .section_product div.product div.summary p.price del,
        .section_product div.product div.summary span.price del {
          margin-right: 10px; }
      .section_product div.product div.summary .woocommerce-variation-price p.price,
      .section_product div.product div.summary .woocommerce-variation-price span.price {
        font-size: 22px;
        margin-bottom: 15px;
        display: block;
        margin-top: 30px; }
        .section_product div.product div.summary .woocommerce-variation-price p.price del,
        .section_product div.product div.summary .woocommerce-variation-price span.price del {
          margin-right: 0; }
      .section_product div.product div.summary form.cart {
        margin-top: 65px;
        margin-bottom: 20px;
        width: 75%; }
        .section_product div.product div.summary form.cart:before, .section_product div.product div.summary form.cart:after {
          content: none; }
        @media screen and (max-width: 767px) {
          .section_product div.product div.summary form.cart {
            margin-top: 20px;
            width: 100%; } }
        .section_product div.product div.summary form.cart .variations {
          margin-bottom: 20px; }
          .section_product div.product div.summary form.cart .variations tr {
            display: block; }
          .section_product div.product div.summary form.cart .variations td.label {
            margin-bottom: 10px;
            display: block; }
            .section_product div.product div.summary form.cart .variations td.label label {
              letter-spacing: 0.2px;
              line-height: 22px; }
          .section_product div.product div.summary form.cart .variations td.value {
            position: relative;
            width: 325px;
            display: flex;
            align-items: center; }
            @media screen and (max-width: 767px) {
              .section_product div.product div.summary form.cart .variations td.value {
                width: 100%; } }
            .section_product div.product div.summary form.cart .variations td.value:after {
              content: '';
              position: absolute;
              top: 50%;
              right: 85px;
              transform: translateY(-50%);
              background: url("../img/down-arrow.svg") right/contain no-repeat;
              width: 15px;
              height: 15px;
              z-index: 5; }
          .section_product div.product div.summary form.cart .variations select {
            flex: 1 1 0;
            border: 1px solid #888888;
            border-radius: 3px;
            height: 30px;
            padding: 0 30px 0 15px;
            -webkit-box-shadow: none;
            box-shadow: none;
            margin: 0;
            cursor: pointer;
            position: relative;
            z-index: 10;
            background-color: transparent; }
          .section_product div.product div.summary form.cart .variations .reset_variations {
            flex: none;
            text-align: center;
            line-height: 1;
            padding: .618em 1em;
            border-radius: 3px;
            color: #515151;
            background-color: #ebe9eb;
            font-weight: 700;
            width: 70px;
            margin-left: 5px; }
        .section_product div.product div.summary form.cart .variations_button {
          display: flex; }
        .section_product div.product div.summary form.cart div.quantity {
          margin-right: 5px; }
          .section_product div.product div.summary form.cart div.quantity .qty {
            height: 100%;
            border: 1px solid #888888;
            border-radius: 3px;
            min-height: 35.75px; }
        .section_product div.product div.summary form.cart .button {
          float: none;
          background-color: #018903; }
      .section_product div.product div.summary .product_meta {
        font-size: 14px; }
        .section_product div.product div.summary .product_meta a {
          color: #888888;
          text-decoration: underline;
          transition: color 0.1s linear; }
          .section_product div.product div.summary .product_meta a:hover {
            color: #000;
            transition: color 0.1s linear; }
    .section_product div.product .woocommerce-tabs {
      flex: none;
      width: 100%; }
      @media screen and (max-width: 767px) {
        .section_product div.product .woocommerce-tabs {
          margin: 50px 0; } }
      .section_product div.product .woocommerce-tabs h2 {
        font-size: 18px;
        margin-bottom: 10px; }
      .section_product div.product .woocommerce-tabs .panel p {
        margin-bottom: 1em; }
    .section_product div.product .related {
      flex: none;
      width: 100%; }

.promo {
  padding: 102px 0 50px; }
  @media screen and (max-width: 767px) {
    .promo {
      padding: 50px 0 30px; } }

@media screen and (max-width: 991px) {
  .promo_cards {
    margin: 40px 0 0; } }

@media screen and (min-width: 992px) {
  .promo_cards {
    display: flex;
    flex-wrap: wrap;
    margin: 66px -20px 0; } }

.promo_card {
  border-radius: 5px;
    border-radius-background-color: rgba(1, 137, 3, 0.03);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden; }
  .promo_card:before {
    content: '';
    position: absolute;
    z-index: 5;
    background-color: #f9fcf9;
    transform: rotate(36.5deg);
    width: 700px;
    height: 700px;
    bottom: -105%;
    left: -105%; }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .promo_card:before {
        bottom: -75%;
        left: -185%; } }
  @media screen and (max-width: 991px) {
    .promo_card {
      margin: 0 auto 30px;
      max-width: 345px; } }
  @media screen and (min-width: 992px) {
    .promo_card {
      margin: 0 20px 30px;
      width: calc(33.33% - 40px); } }
  .promo_card a {
    padding: 88px 25px 52px 25px;
    display: block;
    height: 100%;
    text-align: center;
    position: relative;
    z-index: 10; }
    .promo_card a img,
    .promo_card a svg {
      max-width: 73px;
      max-height: 80px; }
    .promo_card a span {
      display: block;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.5px;
      text-align: center;
      color: #000000;
      margin: 58px 0 17px; }
    .promo_card a i {
      display: block;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      color: #727272; }

.entry-title {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 10px; }

.woocommerce .woocommerce-notices-wrapper,
.woocommerce .woocommerce-result-count,
.woocommerce-page .woocommerce-notices-wrapper,
.woocommerce-page .woocommerce-result-count {
  letter-spacing: 0.2px;
  line-height: 22px; }

.woocommerce .woocommerce-notices-wrapper,
.woocommerce-page .woocommerce-notices-wrapper {
  color: #018903; }

.woocommerce .woocommerce-ordering,
.woocommerce-page .woocommerce-ordering {
  width: 100%;
  max-width: 250px;
  position: relative; }
  @media screen and (max-width: 767px) {
    .woocommerce .woocommerce-ordering,
    .woocommerce-page .woocommerce-ordering {
      float: none;
      clear: both; } }
  .woocommerce .woocommerce-ordering:after,
  .woocommerce-page .woocommerce-ordering:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: url("../img/down-arrow.svg") right/contain no-repeat;
    width: 15px;
    height: 15px;
    z-index: 5; }
  .woocommerce .woocommerce-ordering select,
  .woocommerce-page .woocommerce-ordering select {
    width: 100%;
    border: 1px solid #888888;
    border-radius: 3px;
    height: 30px;
    padding: 0 30px 0 15px;
    box-shadow: none;
    margin: 0;
    cursor: pointer;
    position: relative;
    z-index: 10;
    background-color: transparent; }

.woocommerce ul.products[class*=columns-],
.woocommerce-page ul.products[class*=columns-] {
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
  margin: 0 -10px; }
  @media screen and (max-width: 767px) {
    .woocommerce ul.products[class*=columns-],
    .woocommerce-page ul.products[class*=columns-] {
      margin: 0 -5px; } }
  .woocommerce ul.products[class*=columns-] li.product,
  .woocommerce-page ul.products[class*=columns-] li.product {
    width: calc(25% - 20px);
    margin: 0 10px 20px;
    position: relative;
    border-radius: 3px;
    transition: all 0.2s ease-out; }
    .woocommerce ul.products[class*=columns-] li.product:hover,
    .woocommerce-page ul.products[class*=columns-] li.product:hover {
      box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 0 1px rgba(9, 30, 66, 0.08);
      transform: translateY(-10px);
      transition: all 0.2s ease-out; }
    @media screen and (max-width: 767px) {
      .woocommerce ul.products[class*=columns-] li.product,
      .woocommerce-page ul.products[class*=columns-] li.product {
        width: calc(50% - 10px);
        margin: 0 5px 20px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .woocommerce ul.products[class*=columns-] li.product,
      .woocommerce-page ul.products[class*=columns-] li.product {
        width: calc(33.33% - 20px);
        margin: 0 10px 20px; } }
    .woocommerce ul.products[class*=columns-] li.product .added_to_cart,
    .woocommerce-page ul.products[class*=columns-] li.product .added_to_cart {
      display: none !important; }
    .woocommerce ul.products[class*=columns-] li.product .woocommerce-loop-product__link,
    .woocommerce-page ul.products[class*=columns-] li.product .woocommerce-loop-product__link {
      display: block;
      padding: 20px 20px 70px;
      position: relative;
      z-index: 5;
      height: 100%; }
      .woocommerce ul.products[class*=columns-] li.product .woocommerce-loop-product__link .onsale,
      .woocommerce-page ul.products[class*=columns-] li.product .woocommerce-loop-product__link .onsale {
        width: 75px;
        height: 75px;
        background-color: #ff366d;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px; }
        @media screen and (max-width: 767px) {
          .woocommerce ul.products[class*=columns-] li.product .woocommerce-loop-product__link .onsale,
          .woocommerce-page ul.products[class*=columns-] li.product .woocommerce-loop-product__link .onsale {
            width: 60px;
            height: 60px;
            font-size: 14px; } }
        @media screen and (min-width: 768px) and (max-width: 1199px) {
          .woocommerce ul.products[class*=columns-] li.product .woocommerce-loop-product__link .onsale,
          .woocommerce-page ul.products[class*=columns-] li.product .woocommerce-loop-product__link .onsale {
            width: 67px;
            height: 67px;
            font-size: 16px; } }
      @media screen and (max-width: 767px) {
        .woocommerce ul.products[class*=columns-] li.product .woocommerce-loop-product__link,
        .woocommerce-page ul.products[class*=columns-] li.product .woocommerce-loop-product__link {
          padding: 10px 10px 50px; } }
    .woocommerce ul.products[class*=columns-] li.product .product_type_variable,
    .woocommerce ul.products[class*=columns-] li.product .add_to_cart_button,
    .woocommerce-page ul.products[class*=columns-] li.product .product_type_variable,
    .woocommerce-page ul.products[class*=columns-] li.product .add_to_cart_button {
      position: absolute;
      text-align: center;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10; }
      @media screen and (max-width: 767px) {
        .woocommerce ul.products[class*=columns-] li.product .product_type_variable,
        .woocommerce ul.products[class*=columns-] li.product .add_to_cart_button,
        .woocommerce-page ul.products[class*=columns-] li.product .product_type_variable,
        .woocommerce-page ul.products[class*=columns-] li.product .add_to_cart_button {
          font-size: 12px;
          max-width: 110px;
          padding: 10px 10px; } }
      @media screen and (max-width: 1199px) {
        .woocommerce ul.products[class*=columns-] li.product .product_type_variable,
        .woocommerce ul.products[class*=columns-] li.product .add_to_cart_button,
        .woocommerce-page ul.products[class*=columns-] li.product .product_type_variable,
        .woocommerce-page ul.products[class*=columns-] li.product .add_to_cart_button {
          bottom: 10px;
          margin-top: 0;
          padding: 15px 10px;
          width: 100%;
          max-width: 150px; } }
      @media screen and (min-width: 1200px) {
        .woocommerce ul.products[class*=columns-] li.product .product_type_variable,
        .woocommerce ul.products[class*=columns-] li.product .add_to_cart_button,
        .woocommerce-page ul.products[class*=columns-] li.product .product_type_variable,
        .woocommerce-page ul.products[class*=columns-] li.product .add_to_cart_button {
          width: 100%;
          max-width: 160px; } }
    .woocommerce ul.products[class*=columns-] li.product .woocommerce-loop-product__title,
    .woocommerce-page ul.products[class*=columns-] li.product .woocommerce-loop-product__title {
      text-align: center;
      color: #000; }
      @media screen and (max-width: 767px) {
        .woocommerce ul.products[class*=columns-] li.product .woocommerce-loop-product__title,
        .woocommerce-page ul.products[class*=columns-] li.product .woocommerce-loop-product__title {
          font-size: 0.875em;
          padding-bottom: 0; } }
    .woocommerce ul.products[class*=columns-] li.product .price,
    .woocommerce-page ul.products[class*=columns-] li.product .price {
      color: #018903;
      display: block;
      text-align: center;
      font-weight: 700; }
      @media screen and (max-width: 767px) {
        .woocommerce ul.products[class*=columns-] li.product .price,
        .woocommerce-page ul.products[class*=columns-] li.product .price {
          font-size: 0.75em; } }
      .woocommerce ul.products[class*=columns-] li.product .price ins,
      .woocommerce-page ul.products[class*=columns-] li.product .price ins {
        text-decoration: none;
        font-weight: inherit; }
      .woocommerce ul.products[class*=columns-] li.product .price .woocommerce-price-suffix,
      .woocommerce-page ul.products[class*=columns-] li.product .price .woocommerce-price-suffix {
        display: none; }

/* Bildy custom reset */
html, object, img, ins, kbd, q, s, samp, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

iframe {
  border: 0; }

h1, h2, h3, h4, h5, h6, p, blockquote {
  margin: 0; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  scroll-behavior: smooth;
  margin-top: 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

ul, dl {
  list-style: none;
  margin: 0;
  padding: 0; }

ol {
  margin: 0; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: inherit; }

img {
  -ms-interpolation-mode: bicubic; }

a,
a:hover,
a:focus {
  text-decoration: none;
  background-color: transparent;
  text-decoration-skip-ink: auto; }

pre {
  margin: 0;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

label {
  display: inline-block; }

input,
textarea {
  box-shadow: none;
  margin: 0;
  font-family: 'Roboto', sans-serif; }

input[type=text],
input[type="number"],
input[type="email"],
input[type="button"],
input[type="tel"],
input[type="search"],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  margin: 0;
  border-radius: 0; }
  input[type=text]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="button"]:focus,
  input[type="tel"]:focus,
  input[type="search"]:focus,
  textarea:focus,
  select:focus {
    outline: 1px dotted; }

input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

[type="search"] {
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  resize: vertical; }

select::-ms-expand {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

address,
cite, em {
  font-style: normal; }

fieldset {
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

@media screen and (min-width: 992px) {
  main.main {
    display: flex;
    min-height: inherit; } }

main.main > .content-area {
  width: 100%; }

.section {
  flex: 1 1 0;
  overflow: hidden; }

.container {
  padding: 0 15px;
  max-width: 1110px;
  margin: 0 auto; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .container {
      padding: 0 30px; } }

.title {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  max-width: 900px;
  margin: 0 auto; }
  .title span {
    color: #018903; }
  @media screen and (max-width: 767px) {
    .title {
      font-size: 26px; } }

.subtitle {
  font-size: 24px;
  text-align: center;
  margin: 8px 0 30px; }
  @media screen and (max-width: 767px) {
    .subtitle {
      font-size: 18px; } }

.description {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #888888;
  max-width: 740px;
  margin: 0 auto; }
  .description strong {
    font-weight: bold; }
  @media screen and (max-width: 767px) {
    .description {
      font-size: 14px; } }
  @media screen and (max-width: 991px) {
    .description br {
      display: none; } }

.section_container {
  max-width: 1300px; }
  @media screen and (max-width: 991px) {
    .section_container {
      padding: 15px; }
      .section_container .section_aside {
        margin-top: 50px; } }
  @media screen and (min-width: 992px) {
    .section_container {
      display: flex; } }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .section_container {
      padding: 25px; }
      .section_container .section_content {
        flex: 1 1 400px; }
      .section_container .section_aside {
        flex: 2 1 0;
        margin-top: 100px; } }
  @media screen and (min-width: 1200px) {
    .section_container {
      padding: 50px; }
      .section_container .section_content {
        flex: 2 1 400px; }
      .section_container .section_aside {
        flex: 1 1 0;
        margin-top: 100px; } }

.section_aside {
  visibility: hidden; }

table.stylized_table,
.section_content table,
.woocommerce table.woocommerce-table--order-details,
.woocommerce table.woocommerce-checkout-review-order-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 50px 0;
  width: 75%; }
  @media screen and (max-width: 1199px) {
    table.stylized_table,
    .section_content table,
    .woocommerce table.woocommerce-table--order-details,
    .woocommerce table.woocommerce-checkout-review-order-table {
      width: 100%; } }
  table.stylized_table td,
  table.stylized_table th,
  .section_content table td,
  .section_content table th,
  .woocommerce table.woocommerce-table--order-details td,
  .woocommerce table.woocommerce-table--order-details th,
  .woocommerce table.woocommerce-checkout-review-order-table td,
  .woocommerce table.woocommerce-checkout-review-order-table th {
    border: 1px solid #ebe9eb;
    padding: 10px; }

.woocommerce table.woocommerce-table--order-details,
.woocommerce table.woocommerce-checkout-review-order-table {
  width: 100%;
  margin-top: 0; }

.site-main {
  flex: 1 1 0; }

.footer {
  background-color: #ebebeb;
  padding: 35px 0;
  display: flex;
  position: relative; }

.footer_content {
  flex: 1 1 0;
  display: flex;
  justify-content: space-between; }
  @media screen and (min-width: 992px) {
    .footer_content {
      margin: 0 auto 0 20vw; } }

.footer_contact p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000; }
  .footer_contact p strong {
    font-weight: bold;
    font-size: 15px; }

.footer_contact a {
  color: #018903; }

.footer_social {
  display: flex;
  margin-top: 22px;
  justify-content: flex-end; }
  .footer_social a {
    margin-left: 25px; }
    .footer_social a img {
      width: 47px;
      height: auto; }

.header {
  background-color: #fbfbfb; }
  @media screen and (max-width: 991px) {
    .header {
      display: flex; }
      .header.--active .header_nav > ul {
        transform: translate3d(0, 0, 0);
        box-shadow: 0 8px 16px -4px rgba(9, 30, 66, 0.25);
        transition: transform 0.2s ease-out; }
      .header.--active .header_burger {
        position: fixed;
        right: 0; } }
  @media screen and (min-width: 992px) {
    .header {
      width: 20vw;
      max-width: 300px;
      flex: none; } }

.header_logo {
  display: block; }
  .header_logo img {
    width: 100%;
    height: auto; }
  @media screen and (max-width: 991px) {
    .header_logo {
      flex: none;
      width: 15vw;
      max-width: 170px; } }

@media screen and (max-width: 991px) {
  .header_nav {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: flex-end; } }

@media screen and (min-width: 992px) {
  .header_nav {
    margin: 41px 0;
    border-top: 1px solid #727272; } }

@media screen and (max-width: 991px) {
  .header_nav > ul {
    position: fixed;
    background-color: #fff;
    z-index: 5000;
    top: 0;
    right: 0;
    width: 75vw;
    max-width: 460px;
    padding: 50px 0;
    height: 100vh;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.2s ease-out;
    overflow: auto; } }

.header_nav .menu-item {
  border-bottom: 1px solid #727272; }
  .header_nav .menu-item.current-menu-item a, .header_nav .menu-item.current_page_parent a {
    background-color: #ebebeb; }
  .header_nav .menu-item a {
    font-weight: bold;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #000000;
    display: block;
    padding: 12px 10px 11px 25px; }
    @media screen and (min-width: 992px) {
      .header_nav .menu-item a {
        background-color: #fbfbfb; } }
    .header_nav .menu-item a:hover {
      background-color: #ebebeb; }
  .header_nav .menu-item .sub-menu .menu-item {
    border-bottom: 1px solid transparent; }
    .header_nav .menu-item .sub-menu .menu-item.current-menu-item a, .header_nav .menu-item .sub-menu .menu-item.current_page_parent a {
      background-color: #ebebeb; }
    .header_nav .menu-item .sub-menu .menu-item a {
      padding-left: 50px; }
      @media screen and (max-width: 767px) {
        .header_nav .menu-item .sub-menu .menu-item a {
          padding-left: 40px; } }
      @media screen and (min-width: 992px) {
        .header_nav .menu-item .sub-menu .menu-item a {
          background-color: #fbfbfb; } }

@media screen and (max-width: 991px) {
  .header_burger {
    position: relative;
    z-index: 10000;
    margin: 0 0 0 auto;
    float: right;
    padding: 0;
    outline: none;
    padding: 10px 15px;
    border: 0;
    background-color: transparent; }
    .header_burger div {
      width: 30px;
      height: 20px;
      position: relative; }
      .header_burger div span {
        width: 100%;
        height: 2px;
        background-color: #000;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0); }
      .header_burger div:before, .header_burger div:after {
        content: '';
        position: absolute;
        right: 0;
        background-color: #000;
        height: 2px; }
      .header_burger div:before {
        top: 0;
        width: 100%; }
      .header_burger div:after {
        bottom: 0;
        width: 70%; } }

@media screen and (min-width: 992px) {
  .header_burger {
    display: none; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .section_content {
    padding-right: 30px; } }

@media screen and (min-width: 1200px) {
  .section_content {
    padding-right: 70px; } }

.section_content h1 {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 60px; }

.section_content h2, .section_content h3, .section_content h4, .section_content h5, .section_content h6 {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #018903;
  display: block;
  margin: 50px 0 15px;
  font-weight: bold; }

.section_content p {
  margin: 15px 0;
  letter-spacing: 0.2px;
  line-height: 22px; }

.section_content img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 25px 0; }

.section_content ol, .section_content ul {
  margin: 25px 0;
  padding-left: 50px; }
  .section_content ol li, .section_content ul li {
    letter-spacing: 0.2px;
    line-height: 22px; }

.section_content ul {
  list-style-type: disc; }

.section_content a {
  color: #888888;
  text-decoration: underline;
  transition: color 0.1s linear; }
  .section_content a:hover {
    color: #000;
    transition: color 0.1s linear; }

.section_content strong {
  font-weight: bold; }

.section_content i {
  font-style: italic; }

.aside_block {
  border-bottom: 1px solid #888888;
  padding-bottom: 15px;
  margin-bottom: 30px; }
  .aside_block h3 {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: block;
    margin: 0 0 10px;
    font-weight: bold; }
    @media screen and (max-width: 767px) {
      .aside_block h3 {
        font-size: 14px; } }
  .aside_block form {
    position: relative; }
    .aside_block form input[type="text"],
    .aside_block form input[type="search"] {
      width: 100%;
      border: 1px solid #888888;
      border-radius: 3px;
      height: 30px;
      padding: 0 30px 0 15px; }
    .aside_block form input[type="submit"],
    .aside_block form button[type="submit"] {
      background-color: transparent;
      background: url("../img/sidebar-search.png") center/auto no-repeat;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      border: 0;
      padding: 0;
      width: 28px;
      height: 28px;
      font-size: 0;
      outline: none;
      cursor: pointer; }
  .aside_block ul {
    display: flex;
    flex-wrap: wrap; }
    .aside_block ul li {
      margin: 0 5px 10px 0; }
      .aside_block ul li a {
        border: 1px solid #018903;
        padding: 5px 10px;
        color: #000;
        border-radius: 3px;
        font-size: 14px;
        display: block;
        transition: all 0.15s ease-out; }
        .aside_block ul li a:hover {
          background-color: #018903;
          color: #fff;
          transition: all 0.15s ease-out; }

.events {
	padding: 62px 0 95px;

	@media screen and (max-width: 767px) {
		padding: 40px 0 50px; } }

.events_list {
	margin-top: 60px;
	border-top: 1px solid #727272;

	@media screen and (max-width: 767px) {
		margin-top: 40px; } }

.events_item {
	border-bottom: 1px solid #727272;

	a {
		display: block;
		padding: 28px 0 25px;

		@media screen and (max-width: 767px) {
			padding: 23px 0 20px; }

		time {
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #018903;
			display: block;
			margin-bottom: 10px;

			@media screen and (max-width: 767px) {
				font-size: 14px; } }

		span {
			font-size: 22px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #000000;
			display: block;

			@media screen and (max-width: 767px) {
				font-size: 18px; } } } }

.hero {
	width: 100%;
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 767px) {
		height: calc(100vh - 100px); }

	@media screen and (min-width: 768px) {
		min-height: 700px;
		height: 85vh; } }

.hero_content {
	text-align: center; }

.hero_title {
	font-size: 60px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;

	@media screen and (max-width: 767px) {
		font-size: 50px; } }

.hero_subtitle {
	text-transform: uppercase;
	font-size: 24px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
	position: relative;
	top: 30px;

	@media screen and (max-width: 767px) {
		font-size: 16px; } }

.hero_cta {
	padding: 19px 47px 21px;
	text-align: center;
	display: inline-block;
	font-size: 25px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #018903;
	background-color: #fff !important;
	border-radius: 35px;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
	margin-top: 50px;
	transition: background-color 0.2s ease-out;

	@media screen and (max-width: 767px) {
		max-width: 300px;
		font-size: 22px;
		padding: 15px 20px 17px; }

	&:hover {
		background-color: #e5f3e5 !important;
		transition: background-color 0.2s ease-out; } }

.woocommerce {

	.woocommerce-thankyou-order-received {
		font-weight: bold;
		margin-bottom: 20px;
		color: #018903; }

	ul.order_details {

		li {
			color: #888;

			@media screen and (max-width: 767px) {
				float: none;
				margin: 0 0 5px 0;
				border: 0;
				font-size: 0.75rem; }

			strong {
				font-weight: bold;

				@media screen and (max-width: 767px) {
					display: inline;
					font-size: 0.75rem; } } } }

	.woocommerce-order-details__title {
		font-size: 16px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #018903;
		display: block;
		margin: 50px 0 15px;
		font-weight: bold; }

	.woocommerce-table--order-details {

		td {

			a {
				color: #888888;
				text-decoration: underline;
				transition: color 0.1s linear; }

			&:hover {
				color: #000;
				transition: color 0.1s linear; } } }

	.woocommerce-customer-details {

		.woocommerce-column__title {
			font-size: 16px;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #018903;
			display: block;
			margin: 50px 0 15px;
			font-weight: bold; }

		address {
			padding: 1em;
			line-height: 24px; } } }

.footer {
	background-color: #ebebeb;
	padding: 35px 0;
	display: flex;
	position: relative; }

.footer_content {
	flex: 1 1 0;
	display: flex;
	justify-content: space-between;

	@media screen and (min-width: 992px) {
		margin: 0 auto 0 20vw; } }

.footer_contact {

	p {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.57;
		letter-spacing: normal;
		color: #000000;

		strong {
			font-weight: bold;
			font-size: 15px; } }

	a {
		color: #018903; } }

.footer_social {
	display: flex;
	margin-top: 22px;
	justify-content: flex-end;

	a {
		margin-left: 25px;

		img {
			width: 47px;
			height: auto; } } }
